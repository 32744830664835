import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import Print from 'vue-print-nb'
import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.config.ignoredElements = ['wx-open-launch-weapp']
Vue.use(VueAxios, axios);
Vue.prototype.$axios = axios
Vue.use(Print); //注册
Vue.config.productionTip = false
Vue.use(ElementUI)
// 自定义指令，按钮权限
Vue.directive('has', {
  bind: function (el, binding, vnode) {
    // 获取页面按钮权限
    let btnPermissionsStr = binding.value

    let isExist = false;
    // 获取用户按钮权限
    let btnPermissionsArr = JSON.parse(sessionStorage.getItem("userroles")).permissionValueList;
    if (btnPermissionsArr.indexOf(btnPermissionsStr) > -1) {
      isExist = true;
    } else {
    }
    if (!isExist) {
      el.style = 'display:none';
    }
  }
});
Vue.directive('debounce', {
  inserted(el, binding) {
    el.addEventListener('click', e => {
      el.classList.add('is-disabled')
      el.disabled = true
      setTimeout(() => {
        el.disabled = false
        el.classList.remove('is-disabled')
      }, 1000)//我这里设置的是2000毫秒也就是2秒
    })
  }
})
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  if (to.path.toLowerCase() === '/login') {
    next()
  } else {
    if (!sessionStorage.getItem('token') && to.path.indexOf('/binding') == -1 && to.path.indexOf('/bindinguser') == -1) {
      next({
        path: '/login'
      }) //router文件夹下index.js文件里定义了/login对应的映射
      return
    } else {
      next()
    }
  }
  //如果本地 存在 token 则 不允许直接跳转到 登录页面
  if (to.fullPath === "/Login") {
    if (sessionStorage.getItem('token')) {
      next({
        path: from.fullPath
      });
    } else {
      next();
    }
  }
})
// 重新写入 toFixed 方法
Number.prototype.toFixed = function (n) {

  if (n > 20 || n < 0) {
    throw new RangeError('toFixed() digits argument must be between 0 and 20');
  }
  const number = this;
  if (isNaN(number) || number >= Math.pow(10, 21)) {
    return number.toString();
  }

  if (typeof (n) == 'undefined' || n == 0) {
    return (Math.round(number)).toString();
  }
  let result = number.toString();
  const arr = result.split('.');
  // 整数的情况
  if (arr.length < 2) {
    result += '.';
    for (let i = 0; i < n; i += 1) {
      result += '0';
    }
    return result;
  }
  const integer = arr[0];
  const decimal = arr[1];
  if (decimal.length == n) {
    return result;
  }

  if (decimal.length < n) {
    for (let i = 0; i < n - decimal.length; i += 1) {
      result += '0';
    }
    return result;
  }
  result = integer + '.' + decimal.substr(0, n);
  const last = decimal.substr(n, 1);
  // 四舍五入，转换为整数再处理，避免浮点数精度的损失
  if (parseInt(last, 10) >= 5) {
    const x = Math.pow(10, n);
    result = (Math.round((parseFloat(result) * x)) + 1) / x;
    result = result.toFixed(n);
  }
  return result;
}


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
