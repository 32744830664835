<template>
  <div id="app">
		<router-link to="/Login"></router-link>
    <router-view/>
  </div>
</template>


<script>
export default {
	name: 'App',
  beforeCreate() {
    document.querySelector('body').setAttribute('style', 'margin:0;')
  },
}
</script>